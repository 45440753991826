/** */
import { combineReducers } from 'redux';

import audiences from './reducers/audience';
import payments from './reducers/payment';
import currency from './reducers/currency';
import demos from './reducers/demo';
import effects from './reducers/effect';
import events from './reducers/event';
import contacts from './reducers/contact';
import campaigns from './reducers/campaign';
import header from './reducers/header';
import metadata from './reducers/metadata';
import permissions from './reducers/permission';
import plans from './reducers/plan';
import processes from './reducers/process';
import resources from './reducers/resource';
import referrals from './reducers/referral';
import roles from './reducers/role';
import servers from './reducers/server';
import settings from './reducers/settings';
import sms from './reducers/sms';
import tenants from './reducers/tenant';
import transactionals from './reducers/transactional';
import tutorials from './reducers/tutorial';
import users from './reducers/user';
import user_data from './reducers/user-data';
import walletlogs from './reducers/wallet-log';
import { LOGOUT } from './actions/user-data';

const appReducer = combineReducers({
  audiences,
  payments,
  currency,
  demos,
  effects,
  events,
  contacts,
  campaigns,
  header,
  metadata,
  permissions,
  plans,
  processes,
  resources,
  referrals,
  roles,
  servers,
  settings,
  sms,
  tenants,
  transactionals,
  tutorials,
  users,
  user_data,
  walletlogs
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
