/** */
export const READ_MANY_DEMOS = 'read multiple demos.';
export const READ_ONE_DEMO = 'read one demo.';

export const ADD_ONE_DEMO_TO_STORE = 'add one demo to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add demo search results to store.';
export const ADD_MANY_DEMOS_TO_STORE = 'add multiple demos to store.';
export const LOAD_DEMOS_TO_STORE = 'load up demos to store.';
export const REM_ONE_DEMO_FROM_STORE = 'remove a demo from the store.';

/** */
export const readManyDemos = (payload) => ({
  type: READ_MANY_DEMOS,
  payload
});

export const readOneDemo = (payload) => ({
  type: READ_ONE_DEMO,
  payload
});

export const addManyDemosToStore = (payload) => ({
  type: ADD_MANY_DEMOS_TO_STORE,
  payload
});

export const addOneDemoToStore = (payload) => ({
  type: ADD_ONE_DEMO_TO_STORE,
  payload
});

export const addDemoSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadDemosToStore = (payload) => ({
  type: LOAD_DEMOS_TO_STORE,
  payload
});

export const removeOneDemoFromStore = (payload) => ({
  type: REM_ONE_DEMO_FROM_STORE,
  payload
});
