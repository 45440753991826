import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as DemoService from 'services/users/demo';

export const useDemoService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchDemo = async (id, options = {}) => {
    const { error, payload } = await DemoService.readById(id, { ...options, token });
    if (error) {
      toast.error('Unable to fetch demo at this time.');
      return { demo: payload };
    }

    const { data: demo } = payload;
    return { demo };
  };

  const fetchDemos = async (options = {}) => {
    const { error, payload } = await DemoService.read({ ...options, token });
    if (error) {
      toast.error('Unable to fetch demos at this time.');
      return { demos: [], size: 0 };
    }

    const {
      data: demos,
      meta: { size }
    } = payload;
    return { demos, size };
  };

  const searchDemos = async (keys, keyword, options = {}) => {
    const { error, payload } = await DemoService.search(keys, keyword, { ...options, token });
    if (error) {
      toast.error('Unable to fetch demos at this time.');
      return { demos: [], size: 0 };
    }

    const {
      data: demos,
      meta: { size }
    } = payload;
    return { demos, size };
  };

  return {
    fetchDemo,
    fetchDemos,
    searchDemos
  };
};
