import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import { setPageTitle } from 'store/actions/header';
import { useDemoService } from 'hooks/api/users/demo';
import { addDemoSearchResultsToStore, addManyDemosToStore } from 'store/actions/demo';

export const DemoListPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { fetchDemos, searchDemos } = useDemoService();

  const { is_mobile_view } = useSelector((state) => state.metadata);
  const demos_in_store = useSelector((state) => state.demos);

  const [demos, setDemos] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total_demos, setTotalDemos] = useState(0);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Demos', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDemos(Object.values(demos_in_store));
  }, [demos_in_store]);

  const config = {
    actions: {
      single: () => []
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'meta',
        isTitle: true,
        searchable: true,
        formatter: (value) => `${value.firstname} ${value.lastname}`
      },
      {
        title: 'Email',
        key: 'meta',
        isTagline: true,
        searchable: true,
        formatter: (value) => value.email || 'N/A'
      },
      {
        title: 'Company',
        key: 'meta',
        searchable: true,
        formatter: (value) => value.company_name,
        isBadge: true
      },
      {
        title: 'Phone Number',
        key: 'meta',
        searchable: true,
        formatter: (value) => value.phone_number,
        isBadge: true
      },
      {
        title: 'Subscribers',
        key: 'meta',
        formatter: (value) => value.subscribers,
        isMetadata: true
      }
    ],
    is_search_mode,
    items: demos.sort((a, b) => b.id - a.id),
    search_key: 'name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        default:
          history.push(`/demos/${data.id}`);
      }
    }
  };

  const handleItemClick = (payload) => {};

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const { demos, size } = await fetchDemos({
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      dispatch(addManyDemosToStore(demos.map((demo) => ({ ...demo, meta: demo.metadata }))));
      setTotalDemos(size);
    } catch (e) {
      dispatch(addManyDemosToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const { demos, size } = await searchDemos(keys, keyword, {
        query_string: `page=${page}&population=${population}&sort_by=-created_on`
      });

      setTotalDemos(size);
      if (page === 0)
        return dispatch(
          addDemoSearchResultsToStore(demos.map((demo) => ({ ...demo, meta: demo.metadata })))
        );
      dispatch(addManyDemosToStore(demos.map((demo) => ({ ...demo, meta: demo.metadata }))));
    } catch (e) {
      dispatch(addManyDemosToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = <></>;

  return (
    <div>
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_demos
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          loading_data={loading}
          table_actions={table_actions}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};
